.uploadRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagePreviewModal .previewImg {
  width: 100%;
}

.bigger {
  font-size: 24px;
  font-weight: bold;
}